
import DeleteEdit from "@/modules/services/components/delete-edit.vue";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/vueI18n";
import "firebase/firestore";
import moment from 'moment'

export default {
  data() {
    return {
      // currentUser:'asb',
      drugDealURL: "https://drug-deal-web-app.web.app/job-opportunities/",
      deleteJob: false,
      id: this.$route.params.id,
      job: null,
      creator: null,
      recordId: undefined,
      datePresentation: null
    };
  },
  components: {
    // [DeleteJob.name]: DeleteJob
    [DeleteEdit.name]: DeleteEdit,
  },
  methods: {
    doGoToJobOpportunities() {
      this.$router.push("/job-opportunities");
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doUpdate: "jobs/form/doUpdate",
      doFind: "jobs/view/doFind",
      doFindCity: "jobs/view/doFindCity",
      doFindJobPosition: "jobs/view/doFindJobPosition",
      doFindWorkplace: "jobs/view/doFindWorkplace",
      resetRecord: "jobs/view/resetRecord",
      setRecordMounted: "jobs/view/setRecordMounted",
    }),
    openDeleteDialog() {
      // console.log()
      this.deleteJob = true;
    },
    close() {
      this.deleteJob = false;
    },
    goToEditJob() {
      this.$router.push({ path: "add-job", query: { plan: "private" } });
    },
    doGoToMyJobs() {
      this.$router.push("/my-jobs");
    },
    datePresenter(date) {

      const language = localStorage.getItem('language')
      return moment(new Date(date.seconds * 1000)).locale(language).format('DD-MM-yyyy hh:mm a')

      // let dateUpdated = new Date(date.seconds * 1000).toString().slice(0, 15);
      // // let dateUpdated = date.toString().slice(0,15)
      // return dateUpdated;
    },
    jobDetailsPresenter(name) {
      if (this.isRTL) {
        return name.ar;
      } else {
        return name.en;
      }
    },
    async findRecord(id){
      this.recordId = id;
      await this.resetRecord();
      await this.doFind({
        id
      });
    }
  },
  computed: {
    lang(){
        // debugger    
        return i18n.locale
    },
    ...mapGetters({
      is_screen_xs: "layout/is_screen_xs",
      currentUser: "auth/currentUser",
      record: "jobs/view/record",
      recordUpdate: "jobs/form/record",
      user: "jobs/view/user",
      city: "jobs/view/city",
      region: "jobs/view/region",
      jobPosition: "jobs/view/jobPosition",
      workplace: "jobs/view/workplace",
      recordMounted: "jobs/view/recordMounted",
    }),
    isRTL() {
      return i18n.locale == "ar";
    },
  },
  async mounted() {
    this.setHeaderBg("bg-shadow");
    this.setNavbar("services");
    this.setFooter(false);
  },
  watch: {
    $route(to) {

      // debugger
      this.findRecord(to.params.id)
    },
    lang(){
        document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('drugDeal.services.jobs') 
    },

    // async recordUpdate(newvalue) {
    //   console.log("recordUpdate", newvalue);
    // },
    async record(newvalue, oldvalue) {
      if (newvalue && !oldvalue) {

        this.datePresentation = this.datePresenter(newvalue.createdAt)
        // await this.doFindCity({
        //   cityId: this.record.cityId,
        //   regionId: this.record.regionId,
        // });

        // await this.doFindJobPosition({ id: this.record.positionId });

        // await this.doFindWorkplace({ id: this.record.workplaceId });

        if (this.record.viewers) {
          if (!this.record.viewers.hasOwnProperty(this.currentUser.id)) {
            //   debugger
            this.record.viewers[this.currentUser.id] = true;
            this.record.createdAt = new Date(
              this.record.createdAt.seconds * 1000
            );
            this.record.updatedAt = new Date(
              this.record.updatedAt.seconds * 1000
            );
            if(this.record.dueDate){
               this.record.dueDate = new Date(this.record.dueDate.seconds * 1000); 
            }            
            delete this.record.id;
            this.doUpdate({ id: this.recordId, values: this.record });
          }
        } else {
          this.record.viewers = {};
          this.record.viewers[this.currentUser.id] = true;
          this.record.createdAt = new Date(
            this.record.createdAt.seconds * 1000
          );
          this.record.updatedAt = new Date(
            this.record.updatedAt.seconds * 1000
          );
          this.record.dueDate = new Date(this.record.dueDate.seconds * 1000);
          delete this.record.id;
          this.doUpdate({ id: this.recordId, values: this.record });
        }
      }
    },
    // user(newval,oldval){
    //     console.log('user new val',newval);
    //     console.log('user old val',oldval);

    // },
    // city(newval,oldval){
    //     console.log('city newval',newval);
    //     console.log('city oldval',oldval);
    // },
    // region(newval,oldval){
    //     console.log('region newval',newval);
    //     console.log('region oldval',oldval);
    // },
    // jobPosition(newvalue){
    //     console.log('jobPosition newval',newvalue);
    // },
    // workplace(newvalue){
    //     console.log('workplace newvalue',newvalue);
    // }
  },
  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.jobs') 

    await this.findRecord(this.$route.params.id)
    // this.recordId = this.$route.params.id;
    // await this.resetRecord();
    // console.log("city after reset", this.record);
    // await this.doFind({
    //   // currentUserId: this.currentUser.id,
    //   id: this.$route.params.id,
    // });
  },
};
